import React, { Component } from 'react'
import { observer } from 'mobx-react'
import store from '../../store'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import { SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY, API, ALLOWED_QUERY_OPERATOR, USER_ROLES_TYPES } from '../../constant'
import { prepareSelectParam } from '../../utility/queryParams'
import { isObjectEmpty } from '../../utility/helpers'
import SideNav from '../SideNav'
import FormParent from '../FormParent'
import Home from '../Home'
import Modal from '../reusable/Modal'
import userApi from '../../apis/userApi'
//reusable component
import toast from '../reusable/Toast';

class UserRolesForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                user_id: '',
                role_id: '',
                type: '',
            },
            subscriptionId: '',
            subscriptionIdEmpty: false,
            users: [],
            defaultRoles: [],
            'user-roleValue': '',
            'user-role': [],
            editParams: [],
            validationFields: ['user_id', 'type'],
        };
        this.parameters = [];
    }

    componentDidMount() {
        // Edit not needed for now
        /*if (store.isEdit && store.currentId) {
            let paramFields = ['id', 'user_data', 'role_data', 'type']
            let fields = prepareSelectParam(paramFields)

            fetch(`${API}/user-role/${store.currentId}/?fields=${fields}`, {
                method: 'GET',
                headers: {
                    'Authorization': store.token,
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    let obj = {}
                    Object.keys(this.state.formFields).map(key => {
                        if (key === 'user_id') {
                            obj[key] = !isObjectEmpty(data.data.user_data) ? { 'value': data.data.user_data.id, 'label': `${data.data.user_data.first_name} ${data.data.user_data.last_name}` } : ''
                        }
                        else if (key === 'role_id') {
                            obj[key] = !isObjectEmpty(data.data.role_data) ? { 'value': data.data.role_data.id, 'label': data.data.role_data.name } : ''
                        }
                        else {
                            obj[key] = data.data[key] !== null ? data.data[key] : ''
                        }
                    })
                    this.setState({ formFields: obj })
                })
                .catch(err => {
                    console.log(err)
                })
        }*/

        fetch(`${API}/user?fields=id^,first_name^,last_name`, {
            method: 'GET',
            headers: {
                'Authorization': store.token,
                'Content-Type': 'application/json'
            },
        })
            .then(response => { return response.json() })
            .then(data => {
                if (data.status === 200) {
                    let arr = this.state.users
                    data.data.map(item => {
                        arr.push({ value: item.id, label: `${item.first_name} ${item.last_name}` })
                    })
                    this.setState({ users: arr })
                }
            })

        fetch(`${API}/role?sort=name^:asc&limit=50`, {
            method: 'GET',
            headers: {
                'Authorization': store.token,
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.status === 200) {
                    let arr = []
                    data.data.map(item => {
                        arr.push({ value: item.id, label: item.name })
                    })
                    this.setState({ defaultRoles: arr, })
                }
            })
            .catch(err => {
                console.log(err)
            })

    }

    handleUserSelect = (option) => {
        this.handleSelectChange(option, 'user_id');

        const fields = ['id'];

        const currentData = new Date();
        const filterDate = `${currentData.getFullYear()}-${currentData.getMonth() + 1}-${currentData.getDate()}`
        const filters = [
            { key: 'status', value: [1], op: ALLOWED_QUERY_OPERATOR.equal },
            { key: 'end_date', value: [filterDate], op: ALLOWED_QUERY_OPERATOR.gte }
        ];
        this.setState({
            subscriptionIdEmpty: false
        });

        //get user subscription data
        userApi.getUserSubscription(option.value, fields, filters).then(
            response => {
                if (isObjectEmpty(response)) {
                    this.setState({
                        subscriptionId: ''
                    });
                } else {
                    this.setState({
                        subscriptionId: response[0].id || ''
                    });
                }
            }
        );
    }

    /**
     * Method to add the user roles
     */
    handleAddUserRoles = () => {
        const arrUserRoles = [...this.state['user-role']];
        const objFormFields = { ...this.state.formFields };
        const intSubscriptionId = this.state.subscriptionId;
        const intUserId = objFormFields.user_id.value;
        const strType = objFormFields.type.value;

        // validate the selected roles
        if (arrUserRoles.length === 0) {
            this.setState({ role_idEmpty: true });
            return;
        }

        // check if the user has an active subscription when access if of type subscription
        if (!intSubscriptionId && strType == 'subscription') {
            this.setState({
                subscriptionIdEmpty: true
            });

            toast.error("Require Subscription ID for selected Access Type");

            return;
        }

        this.parameters = [];
        this.setState({ role_idEmpty: false });

        // prepare the request payload
        arrUserRoles.forEach((item) => {
            if (strType == 'subscription') {
                this.parameters.push({ user_id: intUserId, role_id: item.id, type: strType, subscription_id: intSubscriptionId });
            } else {
                this.setState({
                    subscriptionIdEmpty: false
                });

                this.parameters.push({ user_id: intUserId, role_id: item.id, type: strType });
            }
        });

        this.handleAdd('user-role');
    }

    handleAccessTypeChange(option) {
        this.handleSelectChange(option, 'type');
        this.setState({
            subscriptionIdEmpty: false
        });
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT USER ROLES</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD USER ROLES</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >User Name<span className='required'>*</span></label>
                                            <AsyncSelect
                                                name='user_id'
                                                placeholder='Please Select'
                                                styles={this.state.user_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                cacheOptions
                                                value={this.state.formFields.user_id}
                                                defaultOptions={this.state.users}
                                                loadOptions={(value, callback) => this.handleLoadOptions(value, callback, 'user')}
                                                onChange={this.handleUserSelect}
                                            />
                                            {this.state.user_idEmpty && <div className='error'>User cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Subscription ID</label>
                                            <input
                                                className={this.state.subscriptionIdEmpty ? "form-control input-error" : "form-control"}
                                                type="number"
                                                name='subscriptionId'
                                                value={this.state.subscriptionId}
                                                disabled
                                            />
                                            {this.state.subscriptionIdEmpty && <div className='error'>User does not have an active subscription</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Role<span className='required'>*</span></label>
                                            <AsyncSelect
                                                name='role_id'
                                                placeholder='Please Select'
                                                styles={this.state.role_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                cacheOptions
                                                value={this.state['user-roleValue']}
                                                defaultOptions={this.state.defaultRoles}
                                                loadOptions={(value, callback) => this.handleLoadOptions(value, callback, 'role')}
                                                onChange={(option) => this.handleMultiSelectChange(option, 'role_id', 'user-role')}
                                            />
                                            {this.state['user-role'].length > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state['user-role'].map((item, i) => {
                                                        return <li key={i}>{item.name} <i className='fa fa-times-circle' onClick={() => this.handleMultiRemove(i, item.id, 'user-role')}></i></li>
                                                    })}
                                                </div>
                                            }
                                            {this.state.role_idEmpty && <div className='error'>Role cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label >Access Type <span className='required'>*</span></label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.type}
                                                onChange={(option) => this.handleAccessTypeChange(option)}
                                                options={USER_ROLES_TYPES}
                                            />
                                            {this.state.typeEmpty && <div className='error'>Access Type name cannot be empty</div>}
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className='bottom-btn-wrapper'>
                                <div>
                                    <button className="btn-primary bottom-btn" onClick={this.handleAddUserRoles}>Add User</button>
                                    <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                                </div>
                            </div>

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}

                        <Modal />
                    </div>
                }
            </div>
        )
    }
}
export default observer(UserRolesForm)
