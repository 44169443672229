import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

// constants
import {
    SELECT_STYLES,
    SELECT_STYLES_ERROR,
    SELECT_THEME_PRIMARY,
    ORDER_TYPES,
    ORDER_TYPES_VALUE
} from '../../../constant';

function DefaultFormSection(props) {
    return (
        <div className='form-wrapper'>
            <h5>Order Details</h5>
            <div className='row'>
                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                    <label >User Name<span className='required'>*</span></label>
                    <AsyncSelect
                        name='user_id'
                        placeholder='Please Select'
                        styles={props.isUserEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                        theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: SELECT_THEME_PRIMARY
                            }
                        })}
                        cacheOptions
                        value={props.formFields.user_id}
                        defaultOptions={props.users}
                        loadOptions={(value, callback) => props.handleLoadOptions(value, callback, 'user')}
                        onChange={props.handleUserChange}
                    />
                    {props.isUserEmpty && <div className='error'>User cannot be empty</div>}
                </div>
            </div>

            <div className='row'>
                <div className="col-md-6 col-sm-6 col-12 form-group">
                    <label >Order Type <span className='required'>*</span></label>
                    <Select
                        name='order_type'
                        placeholder='Please Select'
                        styles={props.isItemEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                        theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: SELECT_THEME_PRIMARY
                            }
                        })}
                        value={props.formFields.order_type}
                        onChange={(option) => {
                            props.handleSelectChange(option, 'order_type');
                            props.getSubscriptionTaxo(option.value);
                        }}
                        options={ORDER_TYPES}
                    />
                    {props.isItemEmpty && <div className='error'>Order type cannot be empty</div>}
                </div>

                <div className="col-md-6 col-sm-6 col-12 form-group">
                    <label >Magnitt Subscription ID<span className='required'>*</span></label>
                    <input
                        className="form-control"
                        type='text'
                        placeholder='SIDXXXX-XX-X'
                        name='mag_sub_id'
                        value={props.formFields.mag_sub_id}
                        onChange={props.handleInputChange} />
                </div>
            </div>

            <div className='row'>
                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                    <label >
                        {props.itemTypeLabel} <span className='required'>*</span>
                    </label>
                    <Select
                        name='item_id'
                        placeholder='Please Select'
                        styles={props.isItemEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                        theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: SELECT_THEME_PRIMARY
                            }
                        })}
                        value={props.formFields.item_id}
                        onChange={props.handleSubsChange}
                        options={props.itemTaxonomy}
                        isDisabled={!props.formFields.order_type || props.order_type == ORDER_TYPES_VALUE.dataExport}
                        formatOptionLabel={props.formatOptionLabel}
                    />
                    {props.isItemEmpty && <div className='error'>Subscription cannot be empty</div>}
                </div>
            </div>

            <div className='row'>
                <div className="col-md-6 col-sm-12 col-xs-12 form-group">
                    <label >Duration (Months) <span className='required'>*</span></label>
                    <input type="text"
                        className={props.durationEmpty ? "form-control input-error" : "form-control"}
                        value={props.formFields.duration}
                        name='duration'
                        disabled={!props.isSubscriptionField}
                        onChange={props.handleInputChange} />
                    {props.durationEmpty && <div className='error'>Duration cannot be empty</div>}
                </div>

                <div className="col-md-6 col-sm-12 col-xs-12 form-group">
                    <label >Amount ({props.currency}) <span className='required'>*</span></label>
                    <input type="number"
                        className={props.amountEmpty ? "form-control input-error" : "form-control"}
                        value={props.formFields.amount}
                        name='amount'
                        onChange={props.handleInputChange} />
                    {props.amountEmpty && <div className='error'>Amount cannot be empty</div>}
                </div>
            </div>

            <div className='row'>
                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                    <label >Payment Reference</label>
                    <input type="text"
                        className="form-control"
                        value={props.formFields.payment_ref}
                        name='payment_ref'
                        onChange={props.handleInputChange} />
                </div>
            </div>
            <hr />
        </div>
    )
}

export default DefaultFormSection;