import React, { Component } from 'react';
import { observer } from 'mobx-react';

// helpers
import store from '../../../store';

class ViewBrandThemeModal extends Component {

    handleCloseModal = () => {
        $('#brandThemeModal').modal('hide');
    }

    prepareFieldName = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).replace("_", " ");
    }

    renderData = () => {
        let content = <div style={{ textAlign: 'center' }}>No Brand Theme Selected</div>

        if (store?.brandThemeModalData) {
            const arrBrandTheme = Object.keys(store.brandThemeModalData).map((key, index) => {
                return (
                    <tr key={`${key}` + index}>
                        <th scope="col">{this.prepareFieldName(key)}</th>
                        <td  className="d-flex align-items-center">
                            <div
						  style={{
							  width: '20px',
							  height: '20px',
							  backgroundColor: `#${store.brandThemeModalData[key]}`,
							  marginRight: '10px'
						  }}
						  ></div> <span> {store.brandThemeModalData[key]}</span>
                        </td>
                    </tr>
                );
            });

            content = (
                <table className="table table-striped">
                    <tbody>
                        {arrBrandTheme}
                    </tbody>
                </table>
            );
        }
        return content;
    }

    render() {
        return (
            <div className="modal fade" id="brandThemeModal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Brand Theme</h4>
                            <button type="button" className="close" onClick={this.handleCloseModal}>&times;</button>
                        </div>
                        <div className="modal-body">
                            {this.renderData()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default observer(ViewBrandThemeModal);
