import { AgGridReact } from 'ag-grid-react'
import { observer } from 'mobx-react'
import React from 'react'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import { ALLOWED_QUERY_OPERATOR, API, USER_SUBSCRIPTION_COLUMN_HEADERS, ORDER_TYPES, SELECT_STYLES, SELECT_THEME_PRIMARY, RESEARCH_TYPES } from '../../constant'
import store from '../../store'
import { getUsers } from '../../utility/commonApis'
import { prepareSelectParam, prepareFilterParam } from '../../utility/queryParams'
import Home from '../Home'
import Parent from '../Parent'
import ActionRenderer from '../reusable/ActionRenderer'
import Modal from '../reusable/Modal'
import Pagination from '../reusable/Pagination'
import Sort from '../reusable/Sort'
import SideNav from '../SideNav'

class UserSubscriptions extends Parent {
    constructor(props) {
        super(props)

        store.sortType = this.props.location.pathname.split('/')[1]
        this.orderType = this.props.location.pathname.split('/')[2]

        this.state = {
            columnDefs: [],
            context: { componentParent: this },
            frameworkComponents: { actionRenderer: ActionRenderer },
            user_id: '',
            amount_filter: '',
            email_filter: '',
            createdAtFrom: '',
            createdAtTo: '',
            endDateFrom: '',
            endDateTo: '',
            expiryDateTo: '',
            mag_sub_id: '',
            user_subs_status_filter: '',
            user_subs_status_filters: [],
            user_ids: [],
            user_idStore: [],
            showuser_id: false,
            showsubs_type: false,
            showTypes: false,
            showAmounts: false,
            showmag_sub_id: false,
            showuser_subs_status_filter: false,
            item_id_filter: '',
            type: '',
            item_id_filters: [],
            types: [],
            item_id_filterStore: [],
            filters: {
                user_id: [],
                item_id_filter: [],
                type: [],
                created_at: [],
                end_date_filter: [],
                amount_filter: [],
                email_filter: [],
                user_subs_status_filter: [],
                mag_sub_id: []
            },
            filterFields: [
                { 'key': 'txn_payment_type_filter', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [this.orderType.toUpperCase()] },
                { 'key': 'user_subs_status_filter', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'user_id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'item_id_filter', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'type', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'amount_filter', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
                { 'key': 'email_filter', 'op': ALLOWED_QUERY_OPERATOR.substring, 'value': [] },
                { 'key': 'created_at', 'op': ALLOWED_QUERY_OPERATOR.gte, 'value': [] },
                { 'key': 'created_at', 'op': ALLOWED_QUERY_OPERATOR.lte, 'value': [] },
                { 'key': 'end_date_filter', 'op': ALLOWED_QUERY_OPERATOR.gte, 'value': [] },
                { 'key': 'end_date_filter', 'op': ALLOWED_QUERY_OPERATOR.lte, 'value': [] },
                { 'key': 'end_date_filter', 'op': ALLOWED_QUERY_OPERATOR.lte, 'value': [] },
                { 'key': 'mag_sub_id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [] },
            ]
        }

        this.userSubsStatusTax = [
            { value: 0, label: 'Cancelled' },   // todo: This value needs to be confirmed
            { value: 1, label: 'Active' },
            { value: 2, label: 'Non-active' }
        ]
    }

    onGridReady(params) {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
        params.api.sizeColumnsToFit()
    }

    componentDidMount() {
        store.currentFilters = prepareFilterParam(this.state.filterFields);

        document.addEventListener('mousedown', (e) => {
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showuser_id: false }) }
            if (this.item_id_filter) { if (!this.item_id_filter.contains(e.target)) this.setState({ showsubs_type: false }) }
            if (this.type) { if (!this.type.contains(e.target)) this.setState({ showTypes: false }) }
            if (this.user) { if (!this.user.contains(e.target)) this.setState({ showuser_id: false }) }
            if (this.amount_filter) { if (!this.amount_filter.contains(e.target)) this.setState({ showAmounts: false }) }
            if (this.email_filter) { if (!this.email_filter.contains(e.target)) this.setState({ showEmails: false }) }
            if (this.user_subs_status_filter) { if (!this.user_subs_status_filter.contains(e.target)) this.setState({ showuser_subs_status_filter: false }) }
            if (this.mag) { if (!this.mag_sub_id.contains(e.target)) this.setState({ showmag_sub_id: false }) }
        });

        let paramFields = ['id', 'type', 'type_data', 'promo_data', 'subscription_type', 'number_of_users', 'duration', 'user_data','mag_sub_id',
            'subscription_type_data', 'user_subscription_data', 'inbound_transaction_data', 'start_date', 'end_date', 'created_at', 'organization_subscription_data'];

        store.fields = prepareSelectParam(paramFields);
        store.isOrderList = true;

        // this.getSubsType();
        this.getUsers();
        this.fetchListData('order', store.fields)
        this.prepColumns();
    }

    prepColumns = () => {
        const columns = USER_SUBSCRIPTION_COLUMN_HEADERS;
        if (this.orderType === 'offline') {
            columns.map(item => {
                if (item.field === "first_amount_paid" || item.field === "total_amount_paid") {
                    item.hide = true;
                }

                if (item.field === "amount_paid") {
                    item.hide = false;
                }
            });
        } else if (this.orderType === 'online') {
            columns.map(item => {
                if (item.field === "amount_paid") {
                    item.hide = true;
                }

                if (item.field === "first_amount_paid" || item.field === "total_amount_paid") {
                    item.hide = false;
                }
            });
        }

        this.setState({ columnDefs: columns });
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => {
            if (this.word) { if (!this.word.contains(e.target)) this.setState({ showuser_id: false }) }
            if (this.item_id_filter) { if (!this.item_id_filter.contains(e.target)) this.setState({ showsubs_type: false }) }
            if (this.type) { if (!this.type.contains(e.target)) this.setState({ showTypes: false }) }
            if (this.user) { if (!this.user.contains(e.target)) this.setState({ showuser_id: false }) }
            if (this.amount_filter) { if (!this.amount_filter.contains(e.target)) this.setState({ showAmounts: false }) }
            if (this.email_filter) { if (!this.email_filter.contains(e.target)) this.setState({ showEmails: false }) }
            if (this.mag) { if (!this.mag_sub_id.contains(e.target)) this.setState({ showmag_sub_id: false }) }
        })
    }


    getSubscriptionTaxo = async (type = 1) => {
        return new Promise(async (resolve, reject) => {
            try {
                const subTypes = [];
                const stateObj = {};

                const subsFields        = prepareSelectParam(['id', 'name', 'duration', 'price']);
                const researchFields    = prepareSelectParam(['id', 'name', 'price']);
                const researchFitlers   = prepareFilterParam([{ 'key': 'type_id', 'op': ALLOWED_QUERY_OPERATOR.equal, 'value': [RESEARCH_TYPES.paid] }]);

                if (type == 1) {
                    let subscriptions = await fetch(`${API}/subscription/?fields=${subsFields}&limit=300`);
                    subscriptions = await subscriptions.json();
                    subscriptions.data.map(item => subTypes.push({ value: item.id, label: item.name /*, duration: item.duration, price: item.price*/   }));
                    stateObj['item_id_filters']      = subTypes;
                    stateObj['item_id_filterStore']  = subTypes;
                }

                if (type == 2) {
                    let ressearch = await fetch(`${API}/research/?fields=${researchFields}&filters=${researchFitlers}&limit=300&sort=id^:DESC`)
                    ressearch = await ressearch.json();
                    ressearch.data.map(item => subTypes.push({ value: item.id, label: item.name, /*price: item.price*/ }));
                    stateObj['item_id_filters']      = subTypes;
                    stateObj['item_id_filterStore']  = subTypes;
                }

                this.setState(stateObj, () => { resolve() });
            } catch (err) {
                console.log(err);
                reject();
            }
        });
    }

    getUsers = async (e) => {
        let value = "";
        if (e && e.target.value.length > 2) {
            value = e.target.value;
        }

        const response = await getUsers(value);

        this.setState({
            user_ids: response,
            user_idStore: response
        });
    }

    handleEmailFilter = (event) => {
        if (event.keyCode === 13 && this.state.email_filter !== '') {
            const tempFilters = { ...this.state.filters };
            const tempFilterFields = [...this.state.filterFields];

            tempFilters.email_filter = [this.state.email_filter];
            tempFilterFields.map(item => {
                if (item.key === "email_filter") {
                    item.value = [this.state.email_filter];
                }
            })

            this.setState({
                filters: tempFilters,
                filterFields: tempFilterFields,
                email_filter: ""
            }, () => {
                this.handleSearchFilters(store.type);
            });
        }
    }

    handleClearAllFilters(str) {
        const { filterFields, filters } = this.state;
        const tempStateObj              = {};

        Object.keys(filters).map(key => {
            filters[key] = [];
        });

        filterFields.map(item => {
            if (item.key !== 'txn_payment_type_filter') {
                item.value = [];
            }
        });

        tempStateObj['filters']         = filters;
        tempStateObj['filterFields']    = filterFields;
        tempStateObj['createdAtFrom']   = '';
        tempStateObj['createdAtTo']     = '';
        tempStateObj['endDateFrom']     = '';
        tempStateObj['endDateTo']       = '';
        tempStateObj['expiryDateTo']  = '';
        tempStateObj['mag_sub_id']   = '';

        this.setState(tempStateObj, () => {
            store.currentFilters = prepareFilterParam(this.state.filterFields);
            this.fetchListData(str, store.fields);
        });
    }

    render() {
        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            <h3 style={{ textAlign: 'center' }}>SUBSCRIPTIONS</h3>
                            <div className='filter-container'>

                                <div className="filter-row">
                                    <div className="col-md-6 col-12" style={{ paddingLeft: 0 }}>

                                        <Select
                                            placeholder='Purchase type e.g. (Reports, Subscriptions)'
                                            styles={SELECT_STYLES}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: SELECT_THEME_PRIMARY
                                                }
                                            })}
                                            value={this.state.type}
                                            onChange={(option) => {
                                                this.handleSingleSelectFilter(option, 'type');
                                                this.getSubscriptionTaxo(option.value)
                                            }}
                                            options={ORDER_TYPES}
                                        />
                                    </div>

                                    <div className='col-md-6 col-12' style={{ paddingLeft: 0 }}>
                                        <div ref={node => this.item_id_filter = node} className={`filter-firstline ${!this.state.type ? "disabled" : ""}`} >
                                            <div style={{ width: '100%' }}>
                                                <input className='filter-input'
                                                    style={{ paddingLeft: 15 }}
                                                    placeholder="Sub-type (Report or subscription name)"
                                                    value={this.state.item_id_filter}
                                                    onChange={(e) => this.handleInputChange(e.target.value, 'item_id_filter')}
                                                    disabled={!this.state.type}
                                                    onFocus={() => { this.setState({ showDateFounded: false, showsubs_type: true, showIndustries: false }) }} />
                                                {this.state.showsubs_type && this.state.item_id_filters ?
                                                    <div className='filter-dropdown' >
                                                        <div >
                                                            {this.state.filters.item_id_filter.length > 0 ?
                                                                <div className='filter-dropdown-wrapper' >
                                                                    {this.state.filters.item_id_filter.map((loc, i) => {
                                                                        return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'item_id_filter')}>
                                                                            {loc}&nbsp;<i className="fa fa-times-circle"></i>
                                                                        </div>
                                                                    })}
                                                                </div> : null}
                                                        </div>
                                                        <ul className='filter-list-container' style={{ maxHeight: 120 }}>
                                                            <div className='filter-list-wrapper' >
                                                                <div>
                                                                    {this.state.item_id_filters.map((item, j) =>
                                                                        <div key={j}>
                                                                            <li style={{ fontSize: 12, }} onClick={() => this.handleSelectFilter(item, 'item_id_filter')}>
                                                                                {item.label}
                                                                            </li><br />
                                                                        </div>)}
                                                                </div>
                                                                <div className='filter-stickybtn' style={{ maxHeight: 120 }} >
                                                                    <div style={{ width: 140 }}>
                                                                        <a tabIndex='0' className='filter-canclebtn' onClick={() => this.handleClearFilter('item_id_filter')}>Clear</a> | <a tabIndex='0' className='filter-applybtn' onClick={() => { this.setState({ showsubs_type: false }); this.handleSearchFilters('order') }}>Apply</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div> : null}
                                            </div>
                                            {this.state.showsubs_type ? <i className="fa fa-chevron-up " style={{ paddingRight: 10 }} onClick={() => this.setState({ showsubs_type: false })} ></i> :
                                                <i className="fa fa-chevron-down " style={{ paddingRight: 10 }} onClick={() => this.setState({ showsubs_type: true })} ></i>}
                                        </div>
                                    </div>
                                </div>

                                <div className="filter-row" style={{ marginTop: 10 }}>
                                    <div className="col-md-6 col-12" style={{ paddingLeft: 0 }}>
                                        <div ref={node => this.user = node} className='filter-firstline' >
                                            <div style={{ width: '100%' }}>
                                                <input className='filter-input'
                                                    style={{ paddingLeft: 15 }}
                                                    placeholder="Type user name to search"
                                                    onChange={this.getUsers}
                                                    onFocus={() => { this.setState({ showsubs_type: false, showuser_id: true }) }} />
                                                {this.state.showuser_id && this.state.user_ids ?
                                                    <div className='filter-dropdown' >
                                                        <div >
                                                            {this.state.filters.user_id.length > 0 ?
                                                                <div className='filter-dropdown-wrapper' >
                                                                    {this.state.filters.user_id.map((loc, i) => {
                                                                        return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'user_id')}>
                                                                            {loc}&nbsp;<i className="fa fa-times-circle"></i>
                                                                        </div>
                                                                    })}
                                                                </div> : null}
                                                        </div>
                                                        <ul className='filter-list-container'>
                                                            <div className='filter-list-wrapper' >
                                                                <div>
                                                                    {this.state.user_ids.map((item, j) =>
                                                                        <div key={j}>
                                                                            <li style={{ fontSize: 12, }} onClick={() => this.handleSelectFilter(item, 'user_id')}>
                                                                                {item.label}
                                                                            </li><br />
                                                                        </div>)}
                                                                </div>
                                                                <div className='filter-stickybtn ' >
                                                                    <div style={{ width: 140 }}>
                                                                        <a tabIndex='0' className='filter-canclebtn' onClick={() => this.handleClearFilter('user_id')}>Clear</a> &nbsp; | &nbsp;
                                                                        <a tabIndex='0' className='filter-applybtn' onClick={() => { this.setState({ showuser_id: false }); this.handleSearchFilters('order') }}>Apply</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div> : null}
                                            </div>
                                            {this.state.showuser_id ? <i className="fa fa-chevron-up " style={{ paddingRight: 10 }} onClick={() => this.setState({ showuser_id: false })} ></i> :
                                                <i className="fa fa-chevron-down " style={{ paddingRight: 10 }} onClick={() => this.setState({ showuser_id: true })} ></i>}
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                        <div ref={node => this.email_filter = node}>
                                            <input className='filter-input input-border'
                                                placeholder="Type user email to search (ONLY ONE AT A TIME)"
                                                value={this.state.email_filter}
                                                onFocus={() => this.setState({ showuser_id: false, showsubs_type: false, showAmounts: false, showEmails: true })}
                                                onKeyUp={this.handleEmailFilter}
                                                onChange={e => this.setState({ email_filter: e.target.value })} />
                                            {this.state.showEmails && this.state.filters.email_filter.length > 0 ?
                                                <div className='filter-dropdown' style={{ width: '100%' }}>
                                                    <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                        {this.state.filters.email_filter.map((word, i) => {
                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'email_filter')}>
                                                                {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div> : null}
                                        </div>
                                    </div>
                                </div>

                                <div className='filter-row' style={{ marginTop: 10 }}>
                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                        <div ref={node => this.createdAtFrom = node} className='filter-firstline' >
                                            <div style={{ width: '100%' }}>
                                                <DatePicker
                                                    placeholderText="Order date - From"
                                                    className='filter-datepicker-form-control'
                                                    dateFormat="yyyy/MM/dd"
                                                    maxDate={new Date()}
                                                    isClearable={this.state.createdAtFrom}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    selected={this.state.createdAtFrom}
                                                    onChange={(date) => this.handleDateChange(date, 'createdAtFrom', 'created_at', ALLOWED_QUERY_OPERATOR.gte)} />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                        <div ref={node => this.showcreatedAtTo = node} className='filter-firstline' >
                                            <div style={{ width: '100%' }}>
                                                <DatePicker
                                                    placeholderText="Order date - To"
                                                    className='filter-datepicker-form-control'
                                                    dateFormat="yyyy/MM/dd"
                                                    isClearable={this.state.createdAtTo}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    selected={this.state.createdAtTo}
                                                    onChange={(date) => this.handleDateChange(date, 'createdAtTo', 'created_at', ALLOWED_QUERY_OPERATOR.lte)} />
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className='filter-row' style={{ marginTop: 10, paddingRight: 10 }}>
                                    <div ref={node => this.user_subs_status_filter = node} className="col-md-6 col-sm-6 col-xs-12" style={{ paddingRight: 10, paddingLeft: 0 }}>
                                        <div className='filter-firstline' >
                                            <div style={{ width: '100%' }}>
                                                <input className='filter-input'
                                                    style={{ paddingLeft: 15 }}
                                                    placeholder="By Status"
                                                    value={this.state.user_subs_status_filter}
                                                    onChange={(e) => this.handleInputChange(e.target.value, 'user_subs_status_filter')}
                                                    onFocus={() => { this.setState({ showuser_subs_status_filter: true, showuser_id: false, showsubs_type: false, showAmounts: false }) }} />
                                                {this.state.showuser_subs_status_filter ?
                                                    <div className='filter-dropdown'>

                                                        <div>
                                                            {this.state.filters.user_subs_status_filter.length > 0 ?
                                                                <div className='filter-dropdown-wrapper' >
                                                                    {this.state.filters.user_subs_status_filter.map((loc, i) => {
                                                                        return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'user_subs_status_filter')}>
                                                                            {loc}&nbsp;<i className="fa fa-times-circle"></i>
                                                                        </div>
                                                                    })}
                                                                </div> : null}
                                                        </div>

                                                        <ul className='filter-list-container' style={{ maxHeight: 120 }}>
                                                            <div className='filter-list-wrapper' >
                                                                <div>
                                                                    {this.userSubsStatusTax.map((item, j) =>
                                                                        <div key={j}>
                                                                            <li style={{ fontSize: 12, }} onClick={() => this.handleSelectFilter(item, 'user_subs_status_filter')}>
                                                                                {item.label}
                                                                            </li><br />
                                                                        </div>)}
                                                                </div>
                                                                <div className='filter-stickybtn' style={{ maxHeight: 120 }} >
                                                                    <div style={{ width: 140 }}>
                                                                        <a tabIndex='0' className='filter-canclebtn' onClick={() => this.handleClearFilter('user_subs_status_filter')}>Clear</a> | <a tabIndex='0' className='filter-applybtn' onClick={() => { this.setState({ showuser_subs_status_filter: false }); this.handleSearchFilters('order') }}>Apply</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>

                                                    </div> : null}
                                            </div>
                                            {this.state.showuser_subs_status_filter ? <i className="fa fa-chevron-up " style={{ paddingRight: 10 }} onClick={() => this.setState({ showuser_subs_status_filter: false })} ></i> :
                                                <i className="fa fa-chevron-down " style={{ paddingRight: 10 }} onClick={() => this.setState({ showuser_subs_status_filter: true })} ></i>}
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                        <div className='filter-firstline' >
                                            <div style={{ width: '100%' }}>
                                                <DatePicker
                                                    placeholderText="Active - From Date"
                                                    className='filter-datepicker-form-control'
                                                    dateFormat="yyyy/MM/dd"
                                                    isClearable={this.state.endDateFrom}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    selected={this.state.endDateFrom}
                                                    onChange={(date) => this.handleDateChange(date, 'endDateFrom', 'end_date_filter', ALLOWED_QUERY_OPERATOR.gte)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='filter-row' style={{ marginTop: 10, paddingRight: 10 }}>
                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                        <div className='filter-firstline' >
                                            <div style={{ width: '100%' }}>
                                                <DatePicker
                                                    placeholderText="Expiry Date"
                                                    className='filter-datepicker-form-control'
                                                    dateFormat="yyyy/MM/dd"
                                                    isClearable={this.state.expiryDateTo}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    selected={this.state.expiryDateTo}
                                                    onChange={(date) => this.handleDateChange(date, 'expiryDateTo', 'end_date_filter', ALLOWED_QUERY_OPERATOR.lte)} />
                                                {/* onChange={(date) => this.handleExpDateChange(date)} /> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-12" style={{ paddingLeft: 0 }}>
                                        <div>
                                            <input ref={node => this.amount_filter = node}
                                                className='filter-input input-border'
                                                placeholder="Search by amount"
                                                value={this.state.amount_filter}
                                                onFocus={() => this.setState({ showuser_id: false, showsubs_type: false, showAmounts: true })}
                                                onKeyUp={(event) => this.handleKeywordEnter(event, 'amount_filter')}
                                                onChange={e => this.setState({ amount_filter: e.target.value })} />
                                            {this.state.showAmounts && this.state.filters.amount_filter.length > 0 ?
                                                <div className='filter-dropdown' style={{ width: '100%' }}>
                                                    <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                        {this.state.filters.amount_filter.map((word, i) => {
                                                            return <div key={i} className='filter-keyword' onClick={() => this.handleRemoveFilter(i, 'amount_filter')}>
                                                                {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div> : null}
                                        </div>
                                    </div>
                                </div>

                                <div className='filter-row' style={{ marginTop: 10, paddingRight: 10 }}>
                                    <div ref={node => this.mag = node} className="col-md-6 col-sm-6 col-xs-12 filter-firstline filter-spacing">
                                        <div style={{ width: '100%' }}>
                                            <input ref={node => this.input = node}
                                                className='filter-input input-border'
                                                placeholder="Filter by Magnitt Subscription ID"
                                                value={this.state.mag_sub_id}
                                                onFocus={() => this.setState({ showmag_sub_id: true })}
                                                onKeyUp={(e) => this.handleInputEnter(e, 'mag_sub_id')}
                                                onChange={e => this.setState({ mag_sub_id: e.target.value })}
                                            />
                                            {
                                                this.state.showmag_sub_id && this.state.filters.mag_sub_id.length > 0 ?
                                                    <div className='filter-dropdown' style={{ width: '100%' }}>
                                                        <div className='filter-dropdown-wrapper' style={{ borderBottom: 0 }} >
                                                            {this.state.filters.mag_sub_id.map((word, i) => {
                                                                return <div key={i} className='filter-name' onClick={() => this.handleRemoveFilter(i, 'mag_sub_id')}>
                                                                    {word}&nbsp;<i className="fa fa-times-circle"></i>
                                                                </div>
                                                            })}
                                                        </div>
                                                    </div> : null
                                            }
                                        </div>

                                        {
                                            this.state.mag_sub_id.length > 0 &&
											<i className="fa fa-times "
											    style={{ paddingRight: 10 }}
											    onClick={() => {
											        this.input.focus()
											        this.setState({ mag_sub_id: '' })
											    }}>
											</i>
                                        }
                                    </div>
                                </div>
                                <div className='filter-row' style={{ alignItems: 'center', paddingTop: 10 }}>
                                    <div className='col-md-12 col-sm-12 col-xs-12 filter-nextline filter-spacing' style={{ flexDirection: 'row-reverse' }} >
                                        <a tabIndex='2' className="col-md-2 col-sm-2 col-xs-12 filter-searchbtn" onClick={() => this.handleClearAllFilters('order')}>Clear All</a>
                                    </div>
                                </div>

                            </div>

                            <Sort />

                            <div className='ag-theme-balham' style={{ width: '100%' }}>
                                <AgGridReact
                                    reactNext={true}
                                    columnDefs={this.state.columnDefs}
                                    rowData={store.rowData}
                                    domLayout='autoHeight'
                                    context={this.state.context}
                                    frameworkComponents={this.state.frameworkComponents}
                                    onGridReady={(params) => this.onGridReady(params)}
                                    enableCellTextSelection={true}
                                >
                                </AgGridReact>
                            </div>

                            <Pagination />

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}


export default observer(UserSubscriptions)
