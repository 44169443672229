import React from 'react';
import { observer } from 'mobx-react';

// constants
import {
    API,
    ORDER_TYPES_VALUE,
    FIELD_SEPARATOR
} from '../../constant';
import { DATA_EXPORT_SORT } from '../../utility/constants/dataExport/sort';
import { DATA_EXPORT_FILTERS } from '../../utility/constants/dataExport/filters';
import { SUBSCRIPTIONS_IDS } from '../../utility/constants/subscriptions';
import { SUBSCRIPTIONS_ADDONS } from '../../utility/constants/subscription_addons';

// helpers
import store from '../../store';
import { prepareSelectParam } from '../../utility/queryParams';

// apis
import subscriptionApi from '../../apis/subscriptionApi'
import taxonomyApi from '../../apis/taxonomyApi';
import resourceApi from '../../apis/resourceApi';
import dataExportApi from '../../apis/dataExportApi';
import organizationApi from '../../apis/organizationApi';
import apiProductApi from '../../apis/apiProductApi';
import subscriptionAddonApi from '../../apis/subscriptionAddonApi';
import userApi from '../../apis/userApi';

// components
import SideNav from '../SideNav';
import FormParent from '../FormParent';
import Home from '../Home';
import Modal from '../reusable/Modal';
import ErrorPopup from '../reusable/ErrorModal';
import FiltersBuilderModal from '../reusable/dataExport/FiltersBuilderModal';
import DataExportSection from './formSections/DataExportSection';
import APIProductSection from './formSections/APIProductSection';
import DefaultFormSection from './formSections/DefaultFormSection';
import AddressSection from './formSections/AddressSection';
import SubscriptionAddonsSection from './formSections/SubscriptionAddonsSection';

class UserSubscriptionForm extends FormParent {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                user_id: '',
                item_id: '',
                order_type: '',
                amount: '',
                duration: '',
                payment_ref: '',
                name: 'User Subscription',
                // data export
                data_set_type: '',
                fields: '',
                number_of_records: '',
                filters: '',
                sort: '',
                // api product
                organization_id: '',
                api_product_id: '',
                mag_sub_id: '',
            },
            address: {
                user_id: '',
                user_name: '',
                address_line_1: '',
                address_line_2: '',
                state: '',
                country_id: '',
                city: '',
                type: '',
                company_name: '',
                job_title: '',
                tax_code: '',
                phone_number: ''
            },
            users: [],
            // subscriptionTypes: [],
            subscriptionsTaxo: [],
            researchTaxo: [],
            editParams: [],
            validationFields: ['user_id', 'order_type', 'item_id', 'duration'],
            homeValidationFields: ['user_id', 'user_name', 'address_line_1', 'country_id', 'type'],
            officeValidationFields: ['user_id', 'user_name', 'address_line_1', 'country_id', 'type', 'company_name', 'job_title', 'tax_code'],
            currency: 'USD',
            type: '',
            country_id: '',
            countries: [],
            fields: [],
            dataSetTypes: [],
            dataSetTypesProducts: [],
            order_type: '',
            selectedField: '',
            postCreation: {
                data_export_request: {}
            },
            errorMessage: '',
            itemTaxonomy: [],
            arrApiProducts: [],
            organizations: [],
            subscriptionAddons: [],
            selectedSubscriptionAddons: [],
            api_products: []
        }
        this.parameters = {
            user_id: '',
            item_id: '',
            order_type: '',
            amount: '',
            duration: '',
            payment_ref: '',
            user_address: '',
            mag_sub_id: '',
        }
    }

    async componentDidMount() {
        await this.getSubscriptionTaxo();
        await this.getUsers();

        taxonomyApi.getCountries().then(
            response => {
                if (response.length > 0) {
                    const countries = response.map(country => { return {value: country.id, label: country.name} })
                    this.setState({ countries })
                }
            }
        )
    }

    getDataExportProducts = async () => {
        dataExportApi.getProducts().then(
            response => {
                const dataSetTypes = response.map(type => {
                    return { value: type.data_set_type, label: type.data_set_type, resource: type.resource};
                })

                this.setState({ dataSetTypes, dataSetTypesProducts: response });
            }
        );
    }

    getApiProducts = async (fieldName = 'itemTaxonomy') => {
        apiProductApi.getApiProducts().then(
            response => {
                const arrApiProducts = response.map(item => {
                    return { value: item.id, label: item.name, price: item.price, duration: item.duration};
                });

                this.setState({ [fieldName]: arrApiProducts });
            }
        );
    }

    getOrganizations = async (option, callback) => {

        if (option && option.length < 2) {
            return;
        }

        let arrOrganizations = []

        await organizationApi.getOrganizations(option).then(
            response => {
                arrOrganizations = response.map(item => {
                    return { value: item.id, label: item.name, };
                });

                this.setState({ organizations: arrOrganizations });
            }
        );

        if (callback) {
            callback(arrOrganizations);
        }
    }

    getSubscriptionTaxo = async (type = ORDER_TYPES_VALUE.subscription) => {
        const tempFormFields = { ...this.state.formFields };
        tempFormFields.item_id = '';
        tempFormFields.duration = '';
        tempFormFields.amount = '';

        const stateObj = {
            order_type: type,
            formFields: tempFormFields,
            isApiProductAddonSelected: false
        };

        if (type == ORDER_TYPES_VALUE.subscription) {
            const subscriptionValidationFields = ['user_id', 'order_type', 'item_id', 'duration'];

            const subscriptionTaxonomy = await subscriptionApi.getSubscriptionTaxonomy(type);

            const subscriptionsTaxo = subscriptionTaxonomy.map(item => {
                return { value: item.id, label: item.name, duration: item.duration, price: item.price };
            });

            stateObj.itemTaxonomy = subscriptionsTaxo;
            stateObj.validationFields = subscriptionValidationFields;

        } else if (type == ORDER_TYPES_VALUE.report) {
            const reportValidationFields = ['user_id', 'order_type', 'item_id'];

            const reportsTaxonomy = await subscriptionApi.getSubscriptionTaxonomy(type);

            const reportTaxo = reportsTaxonomy.map(item => {
                return { value: item.id, label: item.name, price: item.price };
            });

            stateObj.itemTaxonomy = reportTaxo;
            stateObj.validationFields = reportValidationFields;

        } else if (type == ORDER_TYPES_VALUE.dataExport) {
            const dataExportValidationFields = ['user_id', 'order_type', 'data_set_type', 'fields', 'number_of_records'];

            await this.getDataExportProducts();

            stateObj.validationFields = dataExportValidationFields;
        } else if (type == ORDER_TYPES_VALUE.apiProduct) {
            const requiredFields = ['user_id', 'order_type', 'organization_id', 'item_id', 'amount'];

            await this.getApiProducts();
            await this.getOrganizations();

            stateObj.validationFields = requiredFields;
        }

        this.setState(stateObj);
    }

    getUsers = async () => {
        const userParamFields   = ['id', 'first_name', 'last_name', 'email_data'];
        const userParams        = prepareSelectParam(userParamFields);

        fetch(`${API}/user?fields=${userParams}&sort=id^:DESC`, {
            method: 'GET',
            headers: {
                'Authorization': store.token,
                'Content-Type': 'application/json'
            },
        })
            .then(response => { return response.json() })
            .then(data => {
                if (data.status === 200) {
                    let arr = this.state.users
                    data.data.map(item => {
                        arr.push({ value: item.id, label: `${item.first_name} ${item.last_name}` });
                    })
                    this.setState({ users: arr });
                }
            });
    }

    handleSubsChange = option => {
        let { editParams, formFields } = this.state;
        let tempObj = {};
        let totalAmount = 0;

        if (store.isEdit) {
            editParams.push('item_id')
            tempObj['editParams'] = editParams;
        }

        if (formFields.order_type.value == ORDER_TYPES_VALUE.report) { // report
            totalAmount = option.price;
        }
        else if (option.duration != ORDER_TYPES_VALUE.subscription) { // not a one month subscription
            totalAmount = option.duration * option.price;
        } else {
            totalAmount = option.price;
        }

        formFields['item_id']   = { value: option.value, label: option.label };
        formFields['duration']  = option.duration;
        formFields['amount']    = totalAmount;


        // tempObj[`currency`]         = option.currency;
        tempObj[`itemEmpty`]        = false;
        tempObj['formFields']       = formFields

        this.parameters['item_id']  = option.value;
        this.parameters['duration'] = option.duration;
        this.parameters['amount']   = totalAmount;

        this.setState(tempObj, () => {
            if ((formFields.order_type.value == ORDER_TYPES_VALUE.subscription) && option.value == SUBSCRIPTIONS_IDS.custom_enterprise) {
                this.getSubscriptionAddons();
            }
        });
    }

    formatOptionLabel = ({ value, label, price, duration }) => (
        <div>
            <div>{label}</div>
            {(price || duration) && <div style={{ color: "#798289" }}>
                Price: ${price} {duration && `- Duration: ${duration} Month(s)`}
            </div>}
        </div>
    );

    handleUserChange = (option) => {
        const tempAddress   = { ...this.state.address }
        const userId        = option.value
        const username      = option.label

        tempAddress.user_id     = userId
        tempAddress.user_name   = username

        this.setState({ address: tempAddress }, () => {
            this.handleSelectChange(option, 'user_id')
        })
    }

    handleAddressChange = (event) => {
        const tempAddress   = { ...this.state.address }
        const value         = event.target.value
        const field         = event.target.name

        tempAddress[field] = value

        this.setState({ address: tempAddress })
    }

    handleAddressSelectChange = (option, field) => {
        const tempAddress   = { ...this.state.address }
        const value         = option.value

        tempAddress[field] = value

        this.setState({ address: tempAddress, [field]: option })
    }

    handleValidation = () => {
        const { address, officeValidationFields, homeValidationFields } = this.state
        let errorCount = 0
        let validation = homeValidationFields

        if (address && address.type == 'office') {
            validation = officeValidationFields
        }

        validation.map(item => {
            if (address[item] === '') {
                this.state[`${item}Empty`] = true
                errorCount++
            }
            else {
                this.state[`${item}Empty`] = false
            }
        })

        this.setState(this.state)

        const isEmpty = this.handleEmptyFieldValidation()

        if (!isEmpty || errorCount > 0) {
            return true
        } else {
            return false
        }
    }

    handleAddOrder = async () => {
        if (this.handleValidation()) {
            return;
        }

        const formFields    = this.state.formFields;
        const address       = this.state.address;
        const tempObj       = { ...this.state.postCreation };

        if (this.state.order_type == ORDER_TYPES_VALUE.dataExport) {
            const arrFields = formFields.fields.map(objField => objField.value);

            const arrFilters = formFields.filters ? formFields.filters.map(objFilter => objFilter.value) : [];
            const strFilters = arrFilters.join(FIELD_SEPARATOR);

            tempObj.data_export_request = {
                data_set_type: formFields.data_set_type,
                fields: arrFields,
                number_of_records: formFields.number_of_records,
                filters: strFilters,
                sort: formFields.sort.value,
                user_id: formFields.user_id
            }
        } else if (this.state.order_type == ORDER_TYPES_VALUE.subscription && formFields.item_id.value == SUBSCRIPTIONS_IDS.custom_enterprise) {
            const { subscriptionAddons } = this.state;

            const arrAddonsIds = [];

            subscriptionAddons.forEach(addon => {
                if (addon.is_selected) {
                    arrAddonsIds.push(addon.id);
                }
            });

            this.parameters.subscription_addons = arrAddonsIds;
        }

        this.parameters['user_address'] = address;

        this.setState({
            postCreation: tempObj
        }, () => {
            this.handleAdd('order/offline');
            // this.handleClearFields();
        });
    }

    handleClearFields = () => {
        this.setState({
            type: '',
            country_id: '',
            selectedField: '',
            address: {
                user_id: '',
                user_name: '',
                address_line_1: '',
                address_line_2: '',
                state: '',
                country_id: '',
                city: '',
                type: '',
                company_name: '',
                job_title: '',
                tax_code: '',
                phone_number: ''
            },
            isApiProductAddonSelected: false
        })

        this.handleClear()
    }

    handleDataSetChange = (option) => {
        const { formFields, dataSetTypesProducts } = this.state;
        const tempFormFields = { ...formFields };

        // get product id
        const product = dataSetTypesProducts.find(product => product.data_set_type == option.value);

        tempFormFields.data_set_type    = option;
        tempFormFields.item_id          = product.id;
        tempFormFields.fields           = [];
        tempFormFields.filters          = '';
        tempFormFields.sort             = '';

        this.parameters.item_id         = product.id;
        this.parameters.data_set_type   = option.value;

        this.setState({ formFields: tempFormFields, selectedField: '' }, () => {
            this.getAttributes(product.resource);
            this.getSortOptions(option.value);
        });
    }

    getAttributes = async (resource = '') => {
        const resourceCode = resource.toLowerCase();
        const resourceName = `${resourceCode}-get`;

        await resourceApi.getResourceIdByCode(resourceCode, resourceName).then(
            response => {
                const attributes = response.attributes[resourceCode].map(item => {
                    return { value: item.key, label: item.label };
                });

                this.setState({ fields: attributes });
            }
        );
    }

    getSortOptions = async (dataSetType = '') => {
        const sortList = DATA_EXPORT_SORT[dataSetType];

        this.setState({ sortList });
    }

    handleAddFieldOption = (option, isNew = false) => {
        const tempFormFields    = {...this.state.formFields};
        const tempFields        = [...this.state.fields];

        let objOption = option;
        if (isNew) {
            objOption = {
                value: option,
                label: option
            }

            tempFields.push(objOption);
        }


        if (tempFormFields.fields == "") {
            tempFormFields.fields = [];
        }

        tempFormFields.fields.push(objOption);

        this.setState({
            selectedField: objOption,
            formFields: tempFormFields,
            fields: tempFields
        });
    }

    handleRemoveField = (index) => {
        let tempFormFields = { ...this.state.formFields };
        let fields = [...tempFormFields.fields];

        fields.splice(index, 1);

        tempFormFields.fields = fields;

        this.setState({
            formFields: tempFormFields
        });
    }

    handleAddFilter = (filter) => {
        let tempFiltersList = { ...this.state.formFields };

        if (tempFiltersList.filters == '') {
            tempFiltersList.filters = [];
        }

        tempFiltersList.filters.push(...filter);

        this.setState({
            formFields: tempFiltersList
        });
    }

    handleRemoveFilter = (index, secondaryFilterValue = null) => {
        let tempFormFields = { ...this.state.formFields };
        let filters = [...tempFormFields.filters];

        filters.splice(index, 1);

        // remove the secondary filter if the filter has secondary filter
        // If a primary filter (e.g., 'entity_id') is removed, the corresponding secondary filter (e.g., 'entity_type') is removed.
        // The secondary filter provides additional validation when filtering by entity.
        if (secondaryFilterValue) {
            const secondaryFilterIndex = filters.findIndex(filter => filter.value === secondaryFilterValue);

            if (secondaryFilterIndex > -1) {
                filters.splice(secondaryFilterIndex, 1);
            }
        }

        tempFormFields.filters = filters;

        this.setState({
            formFields: tempFormFields
        });
    }

    showFilterModal = () => {
        const { data_set_type } = this.state.formFields;

        store.filterFieldsOptions = DATA_EXPORT_FILTERS[data_set_type.value];

        $('#filtersBuilderModal').modal({
            backdrop: 'static',
            keyboard: true,
            show: true
        })
    }

    getSubscriptionAddons = () => {
        subscriptionAddonApi.getSubscriptionAddons().then(
            response => {
                const arrAddons = response.map(addon => {
                    addon.is_selected = false;
                    return addon;
                });

                this.setState({
                    subscriptionAddons: arrAddons
                });
            }
        );
    }

    handleSelectAddon = (event) => {
        const { subscriptionAddons } = this.state;
        const id = event.target.id;
        const name = event.target.name;
        const checked = event.target.checked;

        const tempSubscriptionAddons = subscriptionAddons.map(addon => {
            if (addon.name == name && addon.id == id) {
                addon.is_selected = checked;

                if (addon.id == SUBSCRIPTIONS_ADDONS.api_product_addon) {
                    this.handleApiProductAddon();
                }
            }

            return addon;
        })

        this.setState({
            subscriptionAddons: tempSubscriptionAddons
        })
    }

    /**
     * Method to handle the selection of the API product addon
     */
    handleApiProductAddon = () => {
        const { validationFields, isApiProductAddonSelected, formFields } = this.state;

        const tempIsApiProductAddonSelected = !isApiProductAddonSelected;

        let arrTemp = [...validationFields];
        let tempFormFields = { ...formFields };

        // case addon selected: updated the validation fields
        // case addon unselected: remove the api product fields and update the validation fields
        if (tempIsApiProductAddonSelected) {
            arrTemp.push('api_product_id');
            arrTemp.push('organization_id');
        } else {
            tempFormFields.api_product_id = '';
            tempFormFields.organization_id = '';

            delete this.parameters.api_product_id;
            delete this.parameters.organization_id;

            const indexOfApiProduct = arrTemp.findIndex(field => field == 'api_product_id');
            if (indexOfApiProduct > -1) {
                arrTemp.splice(indexOfApiProduct, 1);
            }

            const indexOfOrganization = arrTemp.findIndex(field => field == 'organization_id');
            if (indexOfOrganization > -1) {
                arrTemp.splice(indexOfOrganization, 1);
            }
        }

        this.setState({
            validationFields: arrTemp,
            isApiProductAddonSelected: tempIsApiProductAddonSelected,
            formFields: tempFormFields
        }, () => {
            if (this.state.isApiProductAddonSelected) {
                this.getApiProducts('api_products');
                this.getOrganizations();
            }
        });
    }

    renderErrors = () => {
        const {
            user_idEmpty,
            item_idEmpty,
            durationEmpty,
            user_nameEmpty,
            address_line_1Empty,
            country_idEmpty,
            typeEmpty,
            company_nameEmpty,
            job_titleEmpty,
            tax_codeEmpty,
            data_set_typeEmpty,
            number_of_recordsEmpty,
            fieldsEmpty,
            amountEmpty } = this.state

        let errors = []

        if (user_idEmpty)
            errors.push(<div className='error'>User cannot be empty</div>)
        if (item_idEmpty)
            errors.push(<div className='error'>Subscription type cannot be empty</div>)
        if (durationEmpty)
            errors.push(<div className='error'>Duration cannot be empty</div>)
        if (user_nameEmpty)
            errors.push(<div className='error'>User name cannot be empty</div>)
        if (data_set_typeEmpty)
            errors.push(<div className='error'>Data set type cannot be empty</div>)

        if (number_of_recordsEmpty) {
            errors.push(<div className='error'>Number of records cannot be empty</div>)
        }

        if (fieldsEmpty)
            errors.push(<div className='error'>Fields cannot be empty</div>)
        if (address_line_1Empty)
            errors.push(<div className='error'>Billing address cannot be empty</div>)
        if (country_idEmpty)
            errors.push(<div className='error'>Country cannot be empty</div>)
        if (typeEmpty)
            errors.push(<div className='error'>Purchase type cannot be empty</div>)
        if (company_nameEmpty)
            errors.push(<div className='error'>Company name cannot be empty</div>)
        if (job_titleEmpty)
            errors.push(<div className='error'>Job title cannot be empty</div>)
        if (tax_codeEmpty)
            errors.push(<div className='error'>Tax code cannot be empty</div>)

        if (amountEmpty) {
            errors.push(<div className='error'>Amount cannot be empty</div>)
        }

        return errors
    }

    render() {
        let itemTypeLabel = 'Subscription Type';
        if (this.state.order_type === ORDER_TYPES_VALUE.subscription) {
            itemTypeLabel = 'Subscription Type'
        } else if (this.state.order_type === ORDER_TYPES_VALUE.report) {
            itemTypeLabel = 'Report Name'
        } else if (this.state.order_type === ORDER_TYPES_VALUE.apiProduct) {
            itemTypeLabel = 'API Product Type'
        }

        let isSubscriptionField = false;
        if (this.state.order_type === ORDER_TYPES_VALUE.subscription || this.state.order_type === ORDER_TYPES_VALUE.apiProduct) {
            isSubscriptionField = true;
        }

        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className='content'>

                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className='content-right'>
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT USER SUBSCRIPTION</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD USER SUBSCRIPTION</h3>
                            }

                            <div className='form-container'>
                                <DefaultFormSection
                                    // fields
                                    formFields={this.state.formFields}
                                    order_type={this.state.order_type}
                                    currency={this.state.currency}
                                    itemTypeLabel={itemTypeLabel}
                                    isSubscriptionField={isSubscriptionField}
                                    isUserEmpty={this.state.user_idEmpty}
                                    isItemEmpty={this.state.item_idEmpty}
                                    isDurationEmpty={this.state.durationEmpty}
                                    isAmountEmpty={this.state.amountEmpty}
                                    // data/options lists
                                    users={this.state.users}
                                    itemTaxonomy={this.state.itemTaxonomy}
                                    // helpers
                                    handleLoadOptions={this.handleLoadOptions}
                                    handleUserChange={this.handleUserChange}
                                    handleSelectChange={this.handleSelectChange}
                                    getSubscriptionTaxo={this.getSubscriptionTaxo}
                                    handleSubsChange={this.handleSubsChange}
                                    formatOptionLabel={this.formatOptionLabel}
                                    handleInputChange={this.handleInputChange}
                                />

                                {(this.state.order_type == ORDER_TYPES_VALUE.dataExport) &&
                                    <DataExportSection
                                        // fields
                                        formFields={this.state.formFields}
                                        selectedField={this.state.selectedField}
                                        isDataSetTypeEmpty={this.state.data_set_typeEmpty}
                                        isNumberOfRecordsEmpty={this.state.number_of_recordsEmpty}
                                        isFieldsEmpty={this.state.fieldsEmpty}
                                        // data/options lists
                                        dataSetTypes={this.state.dataSetTypes}
                                        fields={this.state.fields}
                                        sortList={this.state.sortList}
                                        // helpers
                                        handleDataSetChange={this.handleDataSetChange}
                                        handleInputChange={this.handleInputChange}
                                        handleAddFieldOption={this.handleAddFieldOption}
                                        handleRemoveField={this.handleRemoveField}
                                        showFilterModal={this.showFilterModal}
                                        handleRemoveFilter={this.handleRemoveFilter}
                                        handleSelectChange={this.handleSelectChange}
                                    />
                                }

                                {
                                    (this.state.order_type == ORDER_TYPES_VALUE.subscription) &&
                                    (this.state.formFields.item_id.value === SUBSCRIPTIONS_IDS.custom_enterprise) &&
                                    <SubscriptionAddonsSection
                                        // fields
                                        selectedSubscriptionAddons={this.state.selectedSubscriptionAddons}
                                        isOrganizationEmpty={this.state.organization_idEmpty}
                                        // data/options lists
                                        subscriptionAddons={this.state.subscriptionAddons}
                                        // helpers
                                        handleSelectAddon={this.handleSelectAddon}
                                    />
                                }

                                {((this.state.order_type == ORDER_TYPES_VALUE.apiProduct) ||
                                    this.state.isApiProductAddonSelected) &&
                                    <APIProductSection
                                        // fields
                                        formFields={this.state.formFields}
                                        isOrganizationEmpty={this.state.organization_idEmpty}
                                        isApiProductTypeEmpty={this.state.api_product_idEmpty}
                                        isApiProductAddonSelected={this.state.isApiProductAddonSelected}
                                        // data/options lists
                                        organizations={this.state.organizations}
                                        apiProducts={this.state.api_products}
                                        // helpers
                                        handleSelectChange={this.handleSelectChange}
                                        formatOptionLabel={this.formatOptionLabel}
                                        getOrganizations={this.getOrganizations}
                                    />
                                }

                                <AddressSection
                                    // fields
                                    address={this.state.address}
                                    country_id={this.state.country_id}
                                    type={this.state.type}
                                    isFirstAddressLineEmpty={this.state.address_line_1Empty}
                                    isCountryEmpty={this.state.country_idEmpty}
                                    isTypeEmpty={this.state.typeEmpty}
                                    isCompanyNameEmpty={this.state.company_nameEmpty}
                                    isJobTitleEmpty={this.state.job_titleEmpty}
                                    isTaxCodeEmpty={this.state.tax_codeEmpty}
                                    // data/options lists
                                    countries={this.state.countries}
                                    // helpers
                                    handleAddressChange={this.handleAddressChange}
                                    handleAddressSelectChange={this.handleAddressSelectChange}
                                />
                            </div>

                            {this.renderErrors()}

                            <div className='bottom-btn-wrapper'>
                                <div>
                                    <button className="btn-primary bottom-btn" onClick={this.handleAddOrder}>Add Order</button>
                                    <button className="btn-secondary bottom-btn" onClick={this.handleClearFields}>Clear</button>
                                </div>
                            </div>

                        </div>

                        {store.isSideNav && <div className='overlay'> </div>}
                        <FiltersBuilderModal handleAddFilter={this.handleAddFilter}/>
                        <ErrorPopup errorMessage={this.state.errorMessage}/>
                        <Modal />
                    </div>
                }
            </div>
        )
    }
}
export default observer(UserSubscriptionForm)
