import React from 'react'
import { observer } from 'mobx-react'
import cloneDeep from 'lodash/cloneDeep'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import store from '../../store'
import { SELECT_STYLES, SELECT_STYLES_ERROR, SELECT_THEME_PRIMARY, OPTIONS, API, TAXONOMIES, ALLOWED_QUERY_OPERATOR } from '../../constant'
import { getTaxonomyData, getLocations, getIndustries, getMultiBusinessType, getStartupTechnologies, getMultiBranch, getMultiIndustry, getMultiGeoFocus, getProductFiles, getTeams, getPeopleTeams, getCities, getTechnology } from '../../utility/commonApis'
import { getSubIndustries } from '../../utility/taxonomiesApis'
import { prepareFilterParam, prepareSelectParam } from '../../utility/queryParams'
import { isObjectEmpty, updateGeoFocusData } from '../../utility/helpers'
import SideNav from '../SideNav'
import Modal from '../reusable/Modal'
import FormParent from '../FormParent'
import Home from '../Home'
import Team from '../reusable/Team'
import PeopleTeam from '../reusable/PeopleTeam'
import startupApi from '../../apis/startupApi'
import searchApi from '../../apis/searchApi'
import technologyApi from '../../apis/technologyApi'
import ErrorPopup from '../reusable/ErrorModal';

class StartupForm extends FormParent {
    constructor(props) {
        super(props)
        this.isEdit = this.props.match.path.indexOf("/edit") > -1;
        this.entityType = "STARTUP";

        this.state = {
            formFields: {
                name: '',
                previous_name: '',
                logo: '',
                cover_image: '',
                description: '',
                product_video: '',
                date_founded: '',
                inactive_date: '',
                number_of_founders: '',
                min_num_emp: '',
                max_num_emp: '',
                revenue_generating: '',
                fundraising: '',
                min_fund_amount: '',
                max_fund_amount: '',
                development_stage: '',
                primary_industry: '',
                sub_industry: '',
                hq_id: '',
                linkedin: '',
                facebook: '',
                twitter: '',
                website: '',
                email_relations: '',
                problem_solved: '',
                market_opportunity: '',
                competitors: '',
                monetization: '',
                challenges: '',
                owned_by: '',
                is_active: '',
                city_id: '',
                technology_id: '',
                android_app_url: '',
                ios_app_url: ''
            },
            users: [{ 'value': 0, 'label': 'none' }],
            logoMsg: '',
            logoEmpty: false,
            logoError: false,
            logoSuccess: false,
            logoUpload: false,
            cover_imageMsg: '',
            cover_imageEmpty: false,
            cover_imageError: false,
            cover_imageSuccess: false,
            cover_imageUpload: false,
            logoFile: '',
            cover_imageFile: '',
            stages: [],
            types: [],
            locations: [],
            geoLocations: [],
            industries: [],
            subIndustries: [],
            editParams: [],
            editlogo: '',
            editcover_image: '',
            'geo-focusValue': '',
            'product-fileValue': '',
            branchValue: '',
            industryValue: '',
            technologies: [],
            multiFields: {
                userInvite: [],
                team: [],
                branch: [],
                industry: [],
                'business-type': [],
                'geo-focus': [],
                'product-file': [],
                institution: [],
                technology: []
            },
            branch: [],
            'geo-focus': [],
            'business-type': [],
            technology: [],
            institution: [],
            industry: [],
            cities: [],
            'product-file': [],
            fileFields: ['logo', 'cover_image'],
            socialFields: ['linkedin', 'facebook', 'twitter'],
            validationFields: ['name', 'logo', 'description', 'primary_industry', 'development_stage', 'date_founded', 'number_of_founders', 'min_num_emp', 'max_num_emp', 'hq_id', 'revenue_generating', 'fundraising', 'min_fund_amount', 'max_fund_amount'],
            errorMessage: ''
        }
        this.parameters = {
            name: '',
            previous_name: '',
            description: '',
            product_video: '',
            date_founded: '',
            inactive_date: '',
            number_of_founders: '',
            min_num_emp: '',
            max_num_emp: '',
            revenue_generating: '',
            fundraising: '',
            min_fund_amount: '',
            max_fund_amount: '',
            development_stage: '',
            hq_id: '',
            linkedin: '',
            facebook: '',
            twitter: '',
            website: '',
            email_relations: '',
            problem_solved: '',
            market_opportunity: '',
            competitors: '',
            monetization: '',
            challenges: '',
            owned_by: '',
            is_active: '',
            city_id: '',
            technology_id: ''
        }
    }

    async componentDidMount() {
        getLocations().then(res => {
            this.setState({ locations: res, geoLocations: cloneDeep(res) })
        })
        technologyApi.getTechnologyList().then((res) => {
            const formattedRes = res.map(item => ({ value: item.id, label: item.name }));
            this.setState({ technologies: formattedRes })
        })

        if (store.isEdit && store.currentId) {
            const paramFields = ['id', 'name', 'previous_name', 'logo_data', 'cover_image_data', 'description', 'product_video', 'date_founded',
                'number_of_founders', 'min_num_emp', 'max_num_emp', 'development_stage_data', 'revenue_generating', 'fundraising','inactive_date',
                'min_fund_amount', 'max_fund_amount', 'hq_data', 'linkedin', 'facebook', 'twitter', 'website', 'problem_solved',
                'market_opportunity', 'competitors', 'monetization', 'challenges', 'owned_by', 'owned_by_data', 'is_active', 'city_data', 'email_relations', 'android_app_url', 'ios_app_url', 'industry_data', 'technology_data'];

            const fields = prepareSelectParam(paramFields);

            fetch(`${API}/startup/${store.currentId}/?fields=${fields}`, {
                method: 'GET',
                headers: {
                    'Authorization': store.token,
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(async data => {
                    let obj = {}
                    Object.keys(this.state.formFields).map(key => {
                        if (key === 'logo') {
                            obj[key] = !isObjectEmpty(data.data.logo_data) ? data.data.logo_data.name : ''
                        }
                        else if (key === 'cover_image') {
                            obj[key] = !isObjectEmpty(data.data.cover_image_data) ? data.data.cover_image_data.name : ''
                        }
                        else if (key === 'date_founded' || key === 'inactive_date') {
                            obj[key] = data.data[key] !== null ? new Date(data.data[key]) : ''
                        }
                        else if (key === 'hq_id') {
                            obj[key] = !isObjectEmpty(data.data.hq_data) ? { 'value': data.data.hq_data.id, 'label': data.data.hq_data.name } : ''
                        }
                        else if (key === 'city_id') {
                            obj[key] = !isObjectEmpty(data.data.city_data) ? { 'value': data.data.city_data.id, 'label': data.data.city_data.name } : ''
                        }
                        else if (key === 'business_type_id') {
                            obj[key] = !isObjectEmpty(data.data.business_type_data) ? { 'value': data.data.business_type_data.id, 'label': data.data.business_type_data.name } : ''
                        }
                        else if (key === 'technology_id') {
                            obj[key] = !isObjectEmpty(data.data.technology_data) ? { 'value': data.data.technology_data.id, 'label': data.data.technology_data.name } : ''
                        }
                        else if (key === 'development_stage') {
                            obj[key] = !isObjectEmpty(data.data.development_stage_data) ? { 'value': data.data.development_stage_data.id, 'label': data.data.development_stage_data.name } : ''
                        }
                        else if (key === 'revenue_generating' || key === 'fundraising' || key === 'is_active') {
                            obj[key] = data.data[key] === 1 ? { value: 1, label: 'Yes' } : data.data[key] === 0 ? { value: 0, label: 'No' } : ''
                        }
                        else if (key === 'owned_by') {
                            if (data.data.owned_by !== 0 || data.data.owned_by !== null) {
                                obj[key] = !isObjectEmpty(data.data.owned_by_data) ? { 'value': data.data.owned_by_data.id, 'label': `${data.data.owned_by_data.first_name} ${data.data.owned_by_data.last_name}` } : ''
                            }
                            else {
                                obj[key] = { 'value': 0, 'label': 'none' }
                            }
                        }
                        else if (key === 'sub_industry') {
                            obj[key] = !isObjectEmpty(data.data.sub_industry) ? data.data.sub_industry : ''
                        }
                        else {
                            obj[key] = data.data[key] !== null ? data.data[key] : ''
                        }
                    })
                    // this.state.formFields = obj
                    this.setState(prev => ({ formFields: { ...prev.formFields, ...obj } }))

                    if (!isObjectEmpty(data.data.hq_data) && data.data.hq_data.id) {
                        const cities = await getCities(data.data.hq_data.id)
                        this.setState({ cities })
                    }
                })
                .catch(err => {
                    console.log(err)
                })

            startupApi.getLinkedEntities(store.currentId).then(
                response => {
                    if (response.length > 0) {
                        const linkedEntities = response.map(entity => {
                            return { id: entity.linked_id, type: entity.linked_type, name: `${entity.linked_data.name} - ${entity.linked_type}` }
                        });

                        this.setState({ institution: linkedEntities })
                    }
                }
            )

            getMultiBusinessType(`startup/${store.currentId}`).then(res => {
                if (res.length > 0) {
                    this.setState({ 'business-type': res })
                }
            })
            getStartupTechnologies(`startup/${store.currentId}`).then(res => {
                if (res.length > 0) {
                    this.setState({ technology: res })
                }
            })

            getMultiBranch(`startup/${store.currentId}`).then(res => {
                if (res.length > 0) {
                    this.setState({ branch: res })
                }
            })

            getMultiGeoFocus(`startup/${store.currentId}`).then(res => {
                if (res.length > 0) {
                    let { geoLocations } = this.state;
                    let { response, geoLoc } = updateGeoFocusData(res, geoLocations);
                    this.setState({ 'geo-focus': response, geoLocations: geoLoc })
                }
            })

            getProductFiles(`startup/${store.currentId}`).then(res => {
                if (res.length > 0) {
                    this.setState({ 'product-file': res })
                }
            })

            getTeams(`startup/${store.currentId}`)

            getPeopleTeams(`startup/${store.currentId}`)

            const industryFields = ['id', 'industry_data', 'is_primary', 'is_sub_industry']
            const selectedIndustries = await getMultiIndustry(`startup/${store.currentId}`, industryFields);

            if (selectedIndustries.length > 0) {
                const formFields = { ...this.state.formFields }
                const tempArr = [];
                let allSubIndustries = [];
                let parentId;

                selectedIndustries.map(async item => {
                    if (item.is_primary === 1) {
                        formFields.primary_industry = { value: item.industry_data.id, label: item.name, id: item.id };

                        // Get sub industries of selected parent industry
                        parentId = item.industry_data.id;
                        allSubIndustries = await getSubIndustries(parentId);
                    } else if (item.is_primary === 0 && item.is_sub_industry === 0) {
                        tempArr.push(item);
                    } else if (item.is_sub_industry === 1) {
                        formFields.sub_industry = { value: item.industry_data.id, label: item.name, id: item.id };
                    }
                });

                this.setState({ industry: tempArr, formFields: formFields, subIndustries: allSubIndustries });
            }
        }

        getTaxonomyData(TAXONOMIES.devStage).then(res => {
            this.setState({ stages: res })
        })

        getTaxonomyData(TAXONOMIES.businessType).then(res => {
            this.setState({ types: res })
        })

        // Industries
        const filters = prepareFilterParam([
            { key: 'is_deleted', value: [0], op: ALLOWED_QUERY_OPERATOR.equal },
            { key: 'parent_id', value: [0], op: ALLOWED_QUERY_OPERATOR.equal },
        ]);
        getIndustries(filters).then(res => {
            this.setState({ industries: res })
        })

        fetch(`${API}/user?fields=id^,first_name^,last_name`, {
            method: 'GET',
            headers: {
                'Authorization': store.token,
                'Content-Type': 'application/json'
            },
        })
            .then(response => { return response.json() })
            .then(data => {
                if (data.status === 200) {
                    let arr = this.state.users
                    data.data.map(item => {
                        arr.push({ value: item.id, label: `${item.first_name} ${item.last_name}` })
                    })
                    this.setState({ users: arr })
                }
            })


                
    }

    handlePrimaryFocusChange = async (option) => {
        const parentId = option.value;
        const tempFormFields = { ...this.state.formFields };
        const tempEditParams = [...this.state.editParams];

        if (store.isEdit) {
            if (tempEditParams.indexOf('primary_industry') === -1) {
                tempEditParams.push('primary_industry');
            }
        }

        // delete the old sub-industries
        if (!isObjectEmpty(tempFormFields.sub_industry)) {
            store.apis.push({ path: `${store.type}/${store.currentId}/industry/${tempFormFields.sub_industry.id}`, method: 'DELETE' });
        }

        tempFormFields.primary_industry = option;
        tempFormFields.sub_industry = '';

        // Get sub industries of parent industry
        const industries = await getSubIndustries(parentId);

        this.setState({
            subIndustries: industries,
            formFields: tempFormFields,
            editParams: tempEditParams,
            primary_industryEmpty: false
        });
    }

    handleSubIndustyFocusChange = async (option) => {
        const tempFormFields = { ...this.state.formFields };

        // delete old sub-industry
        if (!isObjectEmpty(tempFormFields.sub_industry)) {
            store.apis.push({ path: `${store.type}/${store.currentId}/industry/${tempFormFields.sub_industry.id}`, method: 'DELETE' });
        }

        this.handleSelectChange(option, 'sub_industry');
    }

    handleLinkedEntitySelect = (option, field, str) => {
        const tempInstitution = [...this.state.institution];
        const tempMultiFields = { ...this.state.multiFields };

        if (tempInstitution.length > 0) {
            const index = tempInstitution.findIndex(item => {
                return item.linked_id === option.value
            })

            if (index === -1) {
                const objLinkedEntity = { linked_id: option.value, linked_type: option.type };

                tempMultiFields.institution.push(objLinkedEntity);
                tempInstitution.push({ id: option.value, type: option.type, name: option.label })

                if (store.isEdit) {
                    store.apis.push({ path: `${store.type}/${store.currentId}/institution`, method: 'POST', multiType: str, data: [objLinkedEntity] });
                }
            }
        }
        else {
            const objLinkedEntity = { linked_id: option.value, linked_type: option.type };

            tempMultiFields.institution.push(objLinkedEntity);
            tempInstitution.push({ id: option.value, type: option.type, name: option.label });

            if (store.isEdit) {
                store.apis.push({ path: `${store.type}/${store.currentId}/institution`, method: 'POST', multiType: str, data: [objLinkedEntity] })
            }
        }

        this.setState({
            institutionValue: option,
            institution: tempInstitution,
            multiFields: tempMultiFields
        });
    }

    handleLinkedEntityRemove(index, entityData) {
        const tempInstitution = [...this.state.institution];
        const tempMultiFields = { ...this.state.multiFields };

        if (store.isEdit) {
            store.apis.push({ path: `${store.type}/${store.currentId}/institution/${entityData.id}/linked-type/${entityData.type}`, method: 'DELETE', multiType: 'institution' })
        }

        tempMultiFields.institution.splice(index, 1);
        tempInstitution.splice(index, 1);

        this.setState({
            institutionValue: "",
            institution: tempInstitution,
            multiFields: tempMultiFields
        });
    }

    // get linked institutions options
    loadEntities = async (value, callback) => {
        const response = await searchApi.getEntities(value);

        const entities = response.map(entity => {
            return { value: entity.id, label: `${entity.title} - ${entity.type}`, type: entity.type };
        });

        return callback(entities);
    }

    handleStartupUpdate = () => {
        const tempFormFields = { ...this.state.formFields };
        const tempEditParams = [...this.state.editParams];
        const data = [];

        const primaryIndIndex = tempEditParams.indexOf('primary_industry');
        if (primaryIndIndex > -1) {
            data.push({ industry_id: tempFormFields.primary_industry.value, is_primary: 1 });

            tempEditParams.splice(primaryIndIndex, 1);
        }

        const subIndIndex = tempEditParams.indexOf('sub_industry');
        if (subIndIndex > -1) {
            data.push({ industry_id: tempFormFields.sub_industry.value, is_primary: 0, is_sub_industry: 1 });

            tempEditParams.splice(subIndIndex, 1);
            delete this.parameters.sub_industry;
        }

        if (data.length > 0) {
            store.apis.push({
                method: 'POST',
                path: `startup/${store.currentId}/industry`,
                data,
            });
        }

        this.setState({
            editParams: tempEditParams
        }, () => {
            this.handleUpdate('startup');
        });
    }

    renderErrors = () => {
        let {
            nameEmpty,
            logoMsg,
            logoEmpty,
            logoError,
            logoUpload,
            descriptionEmpty,
            primary_industryEmpty,
            development_stageEmpty,
            date_foundedEmpty,
            number_of_foundersEmpty,
            number_of_foundersNumber,
            min_num_empEmpty,
            min_num_empNumber,
            max_num_empEmpty,
            max_num_empNumber,
            max_num_empMax,
            hq_idEmpty,
            revenue_generatingEmpty,
            fundraisingEmpty,
            min_fund_amountEmpty,
            min_fund_amountNumber,
            max_fund_amountEmpty,
            max_fund_amountNumber,
            max_fund_amountMax,
            linkedinSocial,
            facebookSocial,
            twitterSocial,
            email_relationsInvalid,
        } = this.state

        let errors = []

        if (nameEmpty)
            errors.push(<div className='error'>Startup name cannot be empty</div>)
        if (logoMsg && logoMsg.length > 0)
            errors.push(<div className='error'>{logoMsg}</div>)
        if (logoEmpty)
            errors.push(<div className='error'>Logo cannot be empty</div>)
        if (logoError)
            errors.push(<div className='error'>Upload failed!.</div>)
        if (logoUpload)
            errors.push(<div className='error'>File is chosen but not uploaded.</div>)
        if (descriptionEmpty)
            errors.push(<div className='error'>Description cannot be empty</div>)
        if (primary_industryEmpty)
            errors.push(<div className='error'>Primary industry cannot be empty</div>)
        if (development_stageEmpty)
            errors.push(<div className='error'>Development stage cannot be empty</div>)
        if (date_foundedEmpty)
            errors.push(<div className='error'>Date founded cannot be empty</div>)
        if (number_of_foundersEmpty)
            errors.push(<div className='error'>Number of founders cannot be empty</div>)
        if (number_of_foundersNumber)
            errors.push(<div className='error'>Number of founders should be number</div>)
        if (min_num_empEmpty)
            errors.push(<div className='error'>Minimum number of employees cannot be empty</div>)
        if (min_num_empNumber)
            errors.push(<div className='error'>Minimum number of employees should be number</div>)
        if (max_num_empEmpty)
            errors.push(<div className='error'>Maximum number of employees cannot be empty</div>)
        if (max_num_empNumber)
            errors.push(<div className='error'>Maximum number of employees should be number</div>)
        if (max_num_empMax)
            errors.push(<div className='error'>Maximum number of employees should be greater than Minimum number of employees</div>)
        if (hq_idEmpty)
            errors.push(<div className='error'>Head quarter cannot be empty.</div>)
        if (revenue_generatingEmpty)
            errors.push(<div className='error'>Revenue generating cannot be empty</div>)
        if (fundraisingEmpty)
            errors.push(<div className='error'>Fundraising cannot be empty</div>)
        if (min_fund_amountEmpty)
            errors.push(<div className='error'>Minimum fund amount cannot be empty</div>)
        if (min_fund_amountNumber)
            errors.push(<div className='error'>Minimum fund amount should be number</div>)
        if (max_fund_amountEmpty)
            errors.push(<div className='error'>Maximun fund amount cannot be empty</div>)
        if (max_fund_amountNumber)
            errors.push(<div className='error'>Maximum fund amount should be number</div>)
        if (max_fund_amountMax)
            errors.push(<div className='error'>Maximum fund amount should be greater than Minimum fund amount</div>)
        if (linkedinSocial || facebookSocial || twitterSocial || email_relationsInvalid)
            errors.push(<div className='error'>Invalid Social URL</div>)
        return errors
    }

    render() {

        return (
            <div>
                {!store.isLoggedin ?
                    <Home /> :
                    <div className="content">
                        <div className='content-left mobile-hide'>
                            <SideNav />
                        </div>

                        <div className='mobile-show'>
                            {store.isSideNav ?
                                <div className='content-left'>
                                    <SideNav />
                                </div> :
                                <div className='content-left-bar '>
                                    <i className='fas fa-bars' onClick={() => store.isSideNav = true}></i>
                                </div>
                            }
                        </div>

                        <div className="content-right">
                            {store.isEdit ?
                                <h3 style={{ textAlign: 'center' }}>EDIT STARTUP</h3> :
                                <h3 style={{ textAlign: 'center' }}>ADD STARTUP</h3>
                            }

                            <div className='form-container'>
                                <div className='form-wrapper'>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Startup Name <span className='required'>*</span></label>
                                            <input type="text" className={this.state.nameEmpty ? "form-control input-error" : "form-control"} name='name' value={this.state.formFields.name} onChange={this.handleInputChange} />
                                            {this.state.nameEmpty && <div className='error'>Startup name cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Previous Startup Name (if available)</label>
                                            <input type="text" className="form-control" name='previous_name' value={this.state.formFields.previous_name} onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-12 col-xs-12 form-group">
                                            <label>Startup Logo <span className='required'>*</span></label>
                                            <div className="input-group mb-3">
                                                <div className={this.state.logoEmpty ? "custom-file input-error" : "custom-file"}>
                                                    <div className="form-filename-holder">{this.state.formFields.logo === '' ? 'Choose a File' : this.state.formFields.logo}</div>
                                                    <input type='file' className="custom-file-input" name="logo" onChange={(e) => this.handleImageUpload(e, 'logo')} />
                                                </div>
                                                <div className="input-group-append">
                                                    {
                                                        this.props.location.pathname.includes('startup/edit') ?
                                                            <span className="input-group-text" ><i className="fas fa-image"></i></span> :
                                                            <span className="input-group-text" onClick={() => this.addFileUpload('logo', 'logo', this.state.logoFile)}>Upload</span>
                                                    }
                                                </div>
                                            </div>
                                            {this.state.logoMsg.length > 0 && <div className='error'>{this.state.logoMsg}</div>}
                                            {this.state.logoEmpty && <div className='error'>Logo cannot be empty</div>}
                                            {this.state.logoError && <div className='error'>Upload failed!</div>}
                                            {this.state.logoSuccess && <div className='success'>Upload successful!</div>}
                                            {this.state.logoUpload && <div className='error'>File is chosen but not uploaded</div>}
                                        </div>

                                        <div className="col-md-6 col-sm-12 col-xs-12 form-group">
                                            <label>Startup Cover </label>
                                            <div className="input-group mb-3">
                                                <div className="custom-file">
                                                    <div className="form-filename-holder">{this.state.formFields.cover_image === '' ? 'Choose a File' : this.state.formFields.cover_image}</div>
                                                    <input type='file' className="custom-file-input" name="cover_image" onChange={(e) => this.handleImageUpload(e, 'cover_image')} />
                                                </div>
                                                <div className="input-group-append">
                                                    {
                                                        this.props.location.pathname.includes('startup/edit') ?
                                                            <span className="input-group-text" ><i className="fas fa-image"></i></span> :
                                                            <span className="input-group-text" onClick={() => this.addFileUpload('cover-image', 'cover_image', this.state.cover_imageFile)} >Upload</span>
                                                    }
                                                </div>
                                            </div>
                                            {this.state.cover_imageMsg.length > 0 && <div className='error'>{this.state.cover_imageMsg}</div>}
                                            {this.state.cover_imageError && <div className='error'>Upload failed!.</div>}
                                            {this.state.cover_imageSuccess && <div className='success'>Upload successful!</div>}
                                            {this.state.cover_imageUpload && <div className='error'>File is chosen but not uploaded</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Short Description <span className='required'>*</span></label>
                                            <textarea rows='8'
                                                className={this.state.descriptionEmpty ? "form-control input-error" : "form-control"}
                                                name='description'
                                                value={this.state.formFields.description}
                                                onChange={this.handleInputChange} />
                                            {this.state.descriptionEmpty && <div className='error'>Description cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Product Video </label>
                                            <input className="form-control" name='product_video' value={this.state.formFields.product_video} onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Product Files</label>
                                            <div className="custom-file" style={{ borderRadius: 5 }}>
                                                <div className="form-filename-holder">{this.state['product-fileValue']}</div>
                                                <input type='file' className="custom-file-input" name='product_files' onChange={(ev) => this.handleMultiFileUpload(ev, 'product-file')} multiple />
                                            </div>
                                            {this.state['product-file'].length > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state['product-file'].map((item, i) => {
                                                        return <li key={i}>{item.name} <i className='fa fa-times-circle' onClick={() => this.handleMultiRemove(i, item.id, 'product-file')}></i></li>
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-12 form-group">
                                            <label>Primary Industry <span className="required">*</span></label>
                                            <Select
                                                name='primary_industry'
                                                placeholder='Please Select'
                                                styles={this.state.primary_industryEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.primary_industry}
                                                onChange={(option) => this.handlePrimaryFocusChange(option)}
                                                options={this.state.industries}
                                            />
                                            {this.state.primary_industryEmpty && <div className='error'>Primary industry cannot be empty</div>}
                                        </div>

                                        <div className="col-md-6 col-12 form-group">
                                            <label>Sub Industry</label>
                                            <Select
                                                name="sub_industry"
                                                placeholder='Please Select'
                                                styles={this.state.sub_industryEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.sub_industry}
                                                onChange={(option) => this.handleSubIndustyFocusChange(option)}
                                                options={this.state.subIndustries}
                                            />
                                            {this.state.sub_industryEmpty && <div className='error'>Sub industry cannot be empty</div>}
                                        </div>

                                        <div className="col-md-6 col-12 form-group">
                                            <label>Secondary Industries</label>
                                            <Select
                                                placeholder='Select industry'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.industryValue}
                                                onChange={option => this.handleMultiSelectChange(option, 'industry_id', 'industry')}
                                                options={this.state.industries}
                                            />
                                            {this.state.industry.length > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state.industry.map((item, i) => {
                                                        return <li key={i}>{item.name} <i className='fa fa-times-circle' onClick={() => this.handleMultiRemove(i, item.id, 'industry')}></i></li>
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-12 form-group">
                                            <label>Linked institutions</label>
                                            <AsyncSelect
                                                placeholder='Select Institutions'
                                                styles={SELECT_STYLES}
                                                cacheOptions
                                                value={this.state.institutionValue}
                                                defaultOptions={this.state.institutionsArr}
                                                loadOptions={this.loadEntities}
                                                onChange={option => this.handleLinkedEntitySelect(option, 'linked_id', 'institution')}
                                            />
                                            {this.state.institution.length > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state.institution.map((item, i) => {
                                                        return <li key={i}>{item.name} <i className='fa fa-times-circle' onClick={() => this.handleLinkedEntityRemove(i, item)}></i></li>
                                                    })}
                                                </div>
                                            }
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-12 form-group">
                                            <label>Technologies</label>
                                            <Select
                                                placeholder='Select technology'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state['technologyValue']}
                                                onChange={option => this.handleMultiSelectChange(option, 'technology_id', 'technology')}
                                                options={this.state.technologies}
                                            />
                                            {this.state.technology.length > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state.technology.map((item, i) => {
                                                        return <li key={i}>{item.name} <i className='fa fa-times-circle' onClick={() => this.handleMultiRemove(i, item.id, 'technology')}></i></li>
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-12 form-group">
                                            <label>Business Type</label>
                                            <Select
                                                placeholder='Select business type'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state['business-typeValue']}
                                                onChange={option => this.handleMultiSelectChange(option, 'business_type_id', 'business-type')}
                                                options={this.state.types}
                                            />
                                            {this.state['business-type'].length > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state['business-type'].map((item, i) => {
                                                        return <li key={i}>{item.name} <i className='fa fa-times-circle' onClick={() => this.handleMultiRemove(i, item.id, 'business-type')}></i></li>
                                                    })}
                                                </div>
                                            }
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Development Stage <span className='required'>*</span></label>
                                            <Select
                                                name="development_stage"
                                                placeholder='Please Select'
                                                styles={this.state.development_stageEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.development_stage}
                                                onChange={(option) => this.handleSelectChange(option, 'development_stage')}
                                                options={this.state.stages}
                                            />
                                            {this.state.development_stageEmpty && <div className='error'>Development stage cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Date Founded <span className='required'>*</span></label>
                                            <DatePicker
                                                name="date_founded"
                                                placeholderText="Click to select a date"
                                                className={this.state.date_foundedEmpty ? 'datepicker-form-control input-error' : 'datepicker-form-control'}
                                                dateFormat="yyyy/MM/dd"
                                                maxDate={new Date()}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                selected={this.state.formFields.date_founded}
                                                onChange={(date) => this.handleDateChange(date, 'date_founded')} />
                                            {this.state.date_foundedEmpty && <div className='error'>Date founded cannot be empty</div>}
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Number of Founders <span className='required'>*</span></label>
                                            <input title='number'
                                                className={this.state.number_of_foundersEmpty || this.state.number_of_foundersNumber ? "form-control input-error" : "form-control"}
                                                name='number_of_founders'
                                                value={this.state.formFields.number_of_founders}
                                                onChange={this.handleInputChange} />
                                            {this.state.number_of_foundersEmpty && <div className='error'>Number of founders cannot be empty</div>}
                                            {this.state.number_of_foundersNumber && <div className='error'>Number of founders should be number</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Minimum number of Employees <span className='required'>*</span></label>
                                            <input title='number'
                                                className={this.state.min_num_empEmpty || this.state.min_num_empNumber ? "form-control input-error " : "form-control"}
                                                name='min_num_emp'
                                                value={this.state.formFields.min_num_emp}
                                                onChange={this.handleInputChange} />
                                            {this.state.min_num_empEmpty && <div className='error'>Minimum number of employees cannot be empty</div>}
                                            {this.state.min_num_empNumber && <div className='error'>Minimum number of employees should be number</div>}
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Maximum number of Employees <span className='required'>*</span></label>
                                            <input title='number'
                                                className={this.state.max_num_empEmpty || this.state.max_num_empNumber || this.state.max_num_empMax ? "form-control input-error " : "form-control"}
                                                name='max_num_emp'
                                                value={this.state.formFields.max_num_emp}
                                                onChange={this.handleInputChange} />
                                            {this.state.max_num_empEmpty && <div className='error'>Maximum number of employees be empty</div>}
                                            {this.state.max_num_empNumber && <div className='error'>Maximum number of employees should be number</div>}
                                            {this.state.max_num_empMax && <div className='error'>Maximum number of employees should be greater than Minimum number of employees</div>}
                                        </div>
                                    </div>

                                    <div className='row'>

                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Headquarters<span className='required'>*</span></label>
                                            <Select
                                                name="hq_id"
                                                placeholder='Please Select'
                                                styles={this.state.hq_idEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.hq_id}
                                                onChange={this.handleHQChange}
                                                options={this.state.locations}
                                            />
                                            {this.state.hq_idEmpty && <div className='error'>Headquarter cannot be empty</div>}
                                        </div>

                                        <div className="col-md-6 col-sm-6 col-12 form-group">
                                            <label>City</label>
                                            <AsyncSelect
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                cacheOptions
                                                value={this.state.formFields.city_id}
                                                defaultOptions={this.state.cities}
                                                loadOptions={this.loadCities}
                                                onChange={(option) => this.handleSelectChange(option, 'city_id')}
                                                isDisabled={!this.state.formFields.hq_id}
                                            />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Revenue Generating <span className='required'>*</span></label>
                                            <Select
                                                name="revenue_generating"
                                                placeholder='Please Select'
                                                styles={this.state.revenue_generatingEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.revenue_generating}
                                                onChange={(option) => this.handleSelectChange(option, 'revenue_generating')}
                                                options={OPTIONS}
                                            />
                                            {this.state.revenue_generatingEmpty && <div className='error'>Revenue generating cannot be empty</div>}
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Fundraising? <span className='required'>*</span></label>
                                            <Select
                                                name="fundraising"
                                                placeholder='Please Select'
                                                styles={this.state.fundraisingEmpty ? SELECT_STYLES_ERROR : SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        //primary25: 'lightgray',
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.fundraising}
                                                onChange={(option) => this.handleSelectChange(option, 'fundraising')}
                                                options={OPTIONS}
                                            />
                                            {this.state.fundraisingEmpty && <div className='error'>Fundraising cannot be empty</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Minimum Funding Amount<span className='required'>*</span></label>
                                            <input title='number'
                                                className={this.state.min_fund_amountEmpty || this.state.min_fund_amountNumber ? "form-control input-error" : "form-control"}
                                                name='min_fund_amount'
                                                value={this.state.formFields.min_fund_amount}
                                                onChange={this.handleInputChange} />
                                            {this.state.min_fund_amountEmpty && <div className='error'>Minimum Funding Amount cannot be empty</div>}
                                            {this.state.min_fund_amountNumber && <div className='error'>Minimum Funding Amount should be number</div>}
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Maximum Funding Amount<span className='required'>*</span></label>
                                            <input title='number'
                                                className={this.state.max_fund_amountEmpty || this.state.max_fund_amountNumber || this.state.max_fund_amountMax ? "form-control input-error" : "form-control"}
                                                name='max_fund_amount'
                                                value={this.state.formFields.max_fund_amount}
                                                onChange={this.handleInputChange} />
                                            {this.state.max_fund_amountEmpty && <div className='error'>Maximum Funding Amount cannot be empty</div>}
                                            {this.state.max_fund_amountNumber && <div className='error'>Maximum Funding Amount should be number</div>}
                                            {this.state.max_fund_amountMax && <div className='error'>Maximum Funding Amount should be greater than Minimum Funding Amount</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Branch</label>
                                            <Select
                                                placeholder='Select branch'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.branchValue}
                                                onChange={option => this.handleMultiSelectChange(option, 'location_id', 'branch')}
                                                options={this.state.locations}
                                            />
                                            {this.state.branch.length > 0 &&
                                                <div className='permission-policy-list-wrapper'>
                                                    {this.state.branch.map((item, i) => {
                                                        return <li key={i}>{item.name} <i className='fa fa-times-circle' onClick={() => this.handleMultiRemove(i, item.id, 'branch')}></i></li>
                                                    })}
                                                </div>
                                            }
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Geo Focus</label>
                                            <Select
                                                placeholder='Select geo-focus'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state['geo-focusValue']}
                                                onChange={option => this.handleAddGeoFocus(option, 'location_id', 'geo-focus')}
                                                options={this.state.geoLocations}
                                                isOptionDisabled={(option) => option.isDisabled}
                                            />
                                            {this.state['geo-focus'].length > 0 &&
                                                <div className='permission-policy-list-wrapper geo-focus-list'>
                                                    {this.state['geo-focus'].map((item, i) => {
                                                        return <li key={i}>{item.name} <i className='fa fa-times-circle' onClick={() => this.handleMultiRemoveGeoFocus(i, item.id, 'geo-focus')}></i></li>
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Linkedin URL</label>
                                            <input className={this.state.linkedinSocial ? "form-control input-error " : "form-control"}
                                                name='linkedin'
                                                value={this.state.formFields.linkedin}
                                                onChange={this.handleInputChange} />
                                            {this.state.linkedinSocial && <div className='error'>Invalid url</div>}
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Facebook URL</label>
                                            <input className={this.state.facebookSocial ? "form-control input-error " : "form-control"}
                                                name='facebook'
                                                value={this.state.formFields.facebook}
                                                onChange={this.handleInputChange} />
                                            {this.state.facebookSocial && <div className='error'>Invalid url</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Twitter URL</label>
                                            <input className={this.state.twitterSocial ? "form-control input-error " : "form-control"}
                                                name='twitter'
                                                value={this.state.formFields.twitter}
                                                onChange={this.handleInputChange} />
                                            {this.state.twitterSocial && <div className='error'>Invalid url</div>}
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Website URL</label>
                                            <input className="form-control" name='website' value={this.state.formFields.website} onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Email Relations</label>
                                            <input className={this.state.email_relationsInvalid ? "form-control input-error " : "form-control"}
                                                name='email_relations'
                                                value={this.state.formFields.email_relations}
                                                onChange={this.handleInputChange} />
                                            {this.state.email_relationsInvalid && <div className='error'>Invalid url</div>}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Google play link</label>
                                            <input className={this.state.android_app_url ? "form-control input-error " : "form-control"}
                                                name='android_app_url'
                                                value={this.state.formFields.android_app_url}
                                                onChange={this.handleInputChange} />
                                            {this.state.android_app_url && <div className='error'>Invalid url</div>}
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>IOS store link</label>
                                            <input className={this.state.ios_app_url ? "form-control input-error " : "form-control"}
                                                name='ios_app_url'
                                                value={this.state.formFields.ios_app_url}
                                                onChange={this.handleInputChange} />
                                            {this.state.ios_app_url && <div className='error'>Invalid url</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Problem Solved</label>
                                            <textarea className="form-control" rows='8' name='problem_solved' value={this.state.formFields.problem_solved} onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Market Opportunity</label>
                                            <textarea className="form-control" rows='8' name='market_opportunity' value={this.state.formFields.market_opportunity} onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Competitors</label>
                                            <textarea className="form-control" rows='5' name='competitors' value={this.state.formFields.competitors} onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Monetization</label>
                                            <textarea className="form-control" rows='8' name='monetization' value={this.state.formFields.monetization} onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <label>Challenges</label>
                                            <textarea className="form-control" rows='8' name='challenges' value={this.state.formFields.challenges} onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    {store.isEdit &&
                                        <div className='row'>
                                            <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                                <label>Owner</label>
                                                <AsyncSelect
                                                    placeholder='Please Select'
                                                    styles={SELECT_STYLES}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: SELECT_THEME_PRIMARY
                                                        }
                                                    })}
                                                    cacheOptions
                                                    value={this.state.formFields.owned_by}
                                                    defaultOptions={this.state.users}
                                                    loadOptions={(value, callback) => this.handleLoadOptions(value, callback, 'user')}
                                                    onChange={(option) => this.handleSelectChange(option, 'owned_by')}
                                                />
                                            </div>
                                        </div>
                                    }

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Active? {!store.isEdit && "(Default is yes)"}</label>
                                            <Select
                                                placeholder='Please Select'
                                                styles={SELECT_STYLES}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        //primary25: 'lightgray',
                                                        primary: SELECT_THEME_PRIMARY
                                                    }
                                                })}
                                                value={this.state.formFields.is_active}
                                                onChange={(option) => this.handleSelectChange(option, 'is_active')}
                                                options={OPTIONS}
                                            />
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                            <label>Inactive Date</label>
                                            <DatePicker
                                                name="inactive_date"
                                                placeholderText="Click to select a date"
                                                className='datepicker-form-control'
                                                dateFormat="yyyy/MM/dd"
                                                maxDate={new Date()}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                selected={this.state.formFields.inactive_date}
                                                onChange={(date) => this.handleDateChange(date, 'inactive_date')} />
                                        </div>
                                    </div>

                                    <Team isEdit={this.isEdit} entityType={this.entityType} />

                                    <PeopleTeam isEdit={this.isEdit} entityType={this.entityType} />

                                </div>
                            </div>

                            {this.renderErrors()}

                            <div className='bottom-btn-wrapper'>
                                {store.isEdit ? <button className="btn-primary bottom-btn" disabled={this.state.editParams.length > 0 || store.apis.length > 0 ? false : true} onClick={this.handleStartupUpdate}>Save</button> :
                                    <div>
                                        <button className="btn-primary bottom-btn" onClick={() => this.handleAdd('startup')}>Add startup</button>
                                        <button className="btn-secondary bottom-btn" onClick={this.handleClear}>Clear</button>
                                    </div>
                                }
                            </div>

                        </div>
                        {store.isSideNav && <div className='overlay'> </div>}
                        <ErrorPopup errorMessage={this.state.errorMessage} />
                        <Modal />

                    </div>
                }
            </div>
        )
    }
}

export default observer(StartupForm)
